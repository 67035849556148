
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Détail d'une commande | Livraisons",
  components: {
    Datatable,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
    });
    const tableHeader = ref([
      {
        name: "Type",
        key: "loc_nature",
        sortable: true,
      },
      {
        name: "Référence / Désignation",
        key: "loc_designation",
        sortable: true,
      },
      {
        name: "N° Contrat",
        key: "cnt_num_contrat",
        sortable: true,
      },
      {
        name: "Dates",
        key: "cnt_debut",
        sortable: true,
      },
      {
        name: "CCO / Smart Account",
        key: "cnt_smart_account",
        sortable: true,
      },
      {
        name: "Statut",
        key: "cnt_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "sn_tot",
        sortable: true,
      },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };
    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == "Offre validée") return "btn-icon-success";
      if (status == "Offre déclinée") return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == "Offre validée")
        return "media/icons/duotune/general/gen048.svg";
      if (status == "Offre déclinée")
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(
        "Détail d'une commande | Support et abonnements",
        ["Home", "Board Stillnetwork"]
      );

      const codeToc = router.currentRoute.value.params.code as string;
      let myListLiv = await getAxios("/getSupport/" + codeToc);
      state.myList = myListLiv.results;
      state.initialMyList = myListLiv.results;
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
